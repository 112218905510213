import React, { useEffect, useState, useContext, useRef } from "react";
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";

import Lottie from "lottie-react";
import loadingAnimation from "../11051-botmd.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSignOutAlt,
  faPause,
  faPlay,
  faExchangeAlt,
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";

import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";

import { useLocation } from "wouter";

import { MainContext, MemoContext } from "../store";

import pkg from "../../package.json";

const OT = require("@opentok/client");

const initialState = {
  isPaused: false,
  isMuted: false,
  showLoader: true,
};

const VideoChat = (props) => {
  const [state, setState] = useState(initialState);
  const { store } = useContext(MainContext);
  const { setOpenTok } = useContext(MemoContext);
  const otPubRef = useRef();
  const otSessionsRef = useRef();
  const [location, setLocation] = useLocation();

  const [streamDims, setStreamDims] = useState([]);
  const videoHeights = ["100vh", "50vh"];
  const videoWidths = ["100%", "50%"];

  const toggleVideo = () => {
    const publisher = otPubRef.current.getPublisher();
    console.log(publisher);
    if (state.isPaused === true) {
      publisher.publishVideo(true);
      setState({ ...state, isPaused: false });
    } else {
      publisher.publishVideo(false);
      setState({ ...state, isPaused: true });
    }
  };

  const toggleAudio = () => {
    const publisher = otPubRef.current.getPublisher();
    console.log(publisher);
    if (state.isMuted === true) {
      publisher.publishAudio(true);
      setState({ ...state, isMuted: false });
    } else {
      publisher.publishAudio(false);
      setState({ ...state, isMuted: true });
    }
  };

  const toggleCamera = () => {
    const publisher = otPubRef.current.getPublisher();
    publisher.cycleVideo().then(console.log);
  };

  const endConsult = () => {
    setLocation("/");
  };

  const otEventHandlers = {
    streamCreated: (event) => {
      console.log("Publisher stream created!", otSessionsRef);
      setState({ ...state, showLoader: false });
    },
    streamDestroyed: (event) => {
      console.log("Publisher stream destroyed!");
      endConsult();
    },
    sessionConnected: (event) => {
      console.log("Session Connected!");
    },
  };
  const streamList = (res) => {
    console.log("sl", res);
  };

  useEffect(() => {
    if (
      store.otApiKey === "" &&
      store.otSessionId === "" &&
      store.otToken === ""
    ) {
      console.log(
        "VideoChat",
        store.otApiKey,
        store.otSessionId,
        store.otToken
      );
      setLocation("/");
    } else {
      console.log(
        "VideoChat",
        store.otApiKey,
        store.otSessionId,
        store.otToken
      );
    }
    return () => {
      setState(initialState);
    };
  }, []);

  return (
    <>
      {store.otApiKey !== "" &&
        store.otSessionId !== "" &&
        store.otToken !== "" && (
          <OTSession
            ref={otSessionsRef}
            eventHandlers={otEventHandlers}
            apiKey={store.otApiKey}
            sessionId={store.otSessionId}
            token={store.otToken}
          >
            <OTPublisher
              ref={otPubRef}
              properties={{ height: "125px", width: "125px" }}
              style={{
                zIndex: 1,
                position: "absolute",
                top: "24px",
                right: "24px",
              }}
            />
            <OTStreams>
              <OTSubscriber properties={{ height: "100vh", width: "100vw" }} />
            </OTStreams>
            {state.showLoader && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "100vh",
                }}
              >
                <p style={{ textAlign: "center" }}>Please wait...</p>
                <Lottie
                  style={{ maxHeight: "500px", maxWidth: "500px" }}
                  animationData={loadingAnimation}
                  onClick={() => {
                    alert("Animation by Christopher Chan.");
                  }}
                />
              </div>
            )}
            {store.logoUrl && (
              <img
                src={store.logoUrl}
                style={{
                  zIndex: 1,
                  position: "absolute",
                  bottom: "12px",
                  left: "24px",
                }}
                alt="logo"
              />
            )}
            <p style={{
              zIndex: 2,
              position: "absolute",
              bottom: "12px",
              width: '100vw',
              textAlign: "center",
              color: 'slategray'
            }}>Version: {pkg.version}</p>
            <Fab
              mainButtonStyles={{ backgroundColor: "slate" }}
              icon={<FontAwesomeIcon icon={faPlus} />}
              event="click"
              alwaysShowTitle={true}
            >
              <Action
                text="Change Camera"
                style={{ backgroundColor: "orange" }}
                onClick={toggleCamera}
              >
                <FontAwesomeIcon icon={faExchangeAlt} />
              </Action>
              <Action
                text={state.isPaused ? "Show Video" : "Hide Video"}
                style={{ backgroundColor: "teal" }}
                onClick={toggleVideo}
              >
                <FontAwesomeIcon icon={state.isPaused ? faPlay : faPause} />
              </Action>
              <Action
                text={state.isMuted ? "Unmute Audio" : "Mute Audio"}
                style={{ backgroundColor: "slategray" }}
                onClick={toggleAudio}
              >
                <FontAwesomeIcon
                  icon={state.isMuted ? faMicrophone : faMicrophoneSlash}
                />
              </Action>
              <Action
                text="End Consult"
                style={{ backgroundColor: "red" }}
                onClick={endConsult}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
              </Action>
            </Fab>
          </OTSession>
        )}
    </>
  );
};

export default VideoChat;
