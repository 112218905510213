class SetObjHandler{

  constructor(name) {
    this.name = name;
    this.obj = {};
  }

  set setObj(Obj) {
    Object.keys(Obj).forEach(key =>{
      this.obj[key] = Obj[key];
    })
  }

  get getObj() {
    return this.obj;
  }

  getObjKey(Ind) {
    return this.obj[Object.keys(this.obj)[Ind]];
  }

  getAll() {
    return { name: this.name, obj: this.obj };
  }
}

export default SetObjHandler;
