import React, { useEffect, useState, useContext } from "react";
// import logo from './logo.svg';
// import adrLogo from './android_logo.png'
import "../App.css";
import swal from "sweetalert";

import { useLocation } from "wouter";

import { MainContext, MemoContext } from "../store";

import {
  isIOS,
  isAndroid,
  isMobileSafari,
  isChrome,
  isSamsungBrowser,
} from "react-device-detect";

let isDev = false;

const apiUrl = `https://${window.location.hostname}`;
const devApiUrl = "https://testing.mytelemedicine.com/go/api/";

let token = null;

function Home() {
  const { store, dispatch } = useContext(MainContext);
  const { toggleLoader, setOpenTok } = useContext(MemoContext);
  const [location, setLocation] = useLocation();

  const url = window.location.href;
  var urlSpl, urlSpl2;

  if (url.includes("?v=")) {
    urlSpl = url.split("?v=");
    urlSpl2 = urlSpl[1].split("&m=t");
    if (urlSpl2[0]) {
      token = urlSpl2[0];
    }

    if (url.includes("&m=t")) {
      isDev = true;
    }
  }

  useEffect(() => {
    // alert(token)
    // handle the case where we don't
    if (
      token &&
      store.otToken === "" &&
      store.otSessionId === "" &&
      store.otApiKey === ""
    ) {
      openLinkInBrowser();
    }
  }, []);

  const openLinkInBrowser = () => {
    const api = isDev ? devApiUrl : apiUrl;
    if (isIOS === true && isMobileSafari === false) {
      swal(
        "Browser Not Supported",
        "Please use iOS Safari version 11 or higher. ",
        "error"
      );
    } else if (
      (isAndroid === true && isChrome === false) ||
      isSamsungBrowser === true
    ) {
      swal("Browser Not Supported", "Please use Android Chrome. ", "error", {
        buttons: [
          "Close",
          {
            text: "Download Chrome",
            value: "dl",
            closeModal: true,
            visible: true,
            className: "swal-button2",
          },
        ],
      }).then((res) => {
        if (res === "dl") {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.android.chrome";
        }
      });
    } else if (isIOS === true || isAndroid === true || isChrome === true) {
      console.log(token);
      toggleLoader(true).then(() => {
        toggleLoader(true).then(() => {
          fetch(api + `patient/visit/join/token/${token}`, {
            method: "GET",
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              console.log("patient/visit/join/token", data);
              toggleLoader(false).then(() => {
                if (data.success === true) {
                  setOpenTok(
                    String(data.openTokApiKey),
                    data.sessionId,
                    data.token,
                    data.logoPath && data.logoPath !== "" ? data.logoPath : null
                  ).then(() => {
                    setLocation("/VideoChat");
                  });
                } else {
                  swal("Error", data.message, "error");
                }
              });
            })
            .catch((error) => {
              toggleLoader(false).then(() => {
                alert(error);
              });
            });
        });
      });
    } else {
      swal(
        "Browser Not Supported",
        "Please ensure that your browser supports WebRTC! If you are using a PC or Mac we reccomend using Google Chrome.",
        "error"
      );
    }
  };

  return (
    <div className="App">
      <header className="App-header" style={{ paddingTop: "0px" }}>
        {token && store.showVideoChatBtn === true && (
          <a
            href="javascript:void(0);"
            rel="noopener noreferrer"
            onClick={openLinkInBrowser}
            style={{ color: "white", fontSize: "medium" }}
          >
            Open In Browser
          </a>
        )}
      </header>
    </div>
  );
}

export default Home;
