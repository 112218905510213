import React, { useReducer, useMemo } from "react";

import "./App.css";

//import useLocation from "wouter/use-location";

import Router from "./utils/router";
import { useHashLocation } from "./utils/location"; //Hash Router
import MainMemo from "./utils/memo";

import {
  MainContext,
  MainReducer,
  MainInitState,
  MemoContext,
} from "./store";

function App() {
  const [store, dispatch] = useReducer(MainReducer, MainInitState);
  const memoContext = useMemo(MainMemo(store, dispatch), [store]);

  return (
    <MainContext.Provider value={{ store, dispatch }}>
      <MemoContext.Provider value={memoContext}>
        <div className={store.showLoader ? 'loading-mask' : ''}>
          <Router hook={useHashLocation} />
        </div>
      </MemoContext.Provider>
    </MainContext.Provider>
  );
}

export default App;
