const initialState = {
  otApiKey: "46435872",
  otSessionId: "1_MX40NjQzNTg3Mn5-MTYxODg0NTQ3NTQ5Mn43WFpUak5LcDNDNWxmZDQyQlM0TDNmZ1p-fg",
  otToken: "T1==cGFydG5lcl9pZD00NjQzNTg3MiZzaWc9NWVmZDMxNWJkYjg4ODE5YWYxMzNjOTVjZjgxMTE4MjRlNTBmNWRhNDpzZXNzaW9uX2lkPTFfTVg0ME5qUXpOVGczTW41LU1UWXhPRGcwTlRRM05UUTVNbjQzV0ZwVWFrNUxjRE5ETld4bVpEUXlRbE0wVERObVoxcC1mZyZjcmVhdGVfdGltZT0xNjE4ODQ1NDk5Jm5vbmNlPTAuMDE3MjU1Mjk5MzY2NjQzMDUmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTYxODkzMTg5OCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==",
  showLoader: false,
  showVideoChatBtn: false,
  qrEnabled: false,
  testWebOT: false,
  logoUrl: null
};

export default initialState;
