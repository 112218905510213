import React from "react";
import { Router, Route, Switch } from "wouter";

import pkg from '../../package.json';

import Home from "../views/Home";
import VideoChat from "../views/VideoChat";
// import Another from "../views/Another";
// import Custom from "../views/Custom";

export default (props) => {
  return (
    <React.Fragment>
      <Router hook={props.hook} base={pkg.homepage}>
        <Switch>
          <Route path="/" component={Home} />
          <Route path="/a:rest*" component={Home} />
          <Route path="/VideoChat" component={VideoChat} />
          {/*<Route path="/another" component={Another} />
          <Route path="/custom" component={Custom} />*/}
          <Route path="/:rest*">404, not found!</Route>
        </Switch>
      </Router>
    </React.Fragment>
)
};
