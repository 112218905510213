const MainMemo = (store, dispatch) => {
  const MemoContext = () => ({
    toggleLoader: (isOn) => {
      return new Promise(async (resolve) => {
        await dispatch({ type: "set", data: { showLoader: isOn } });
        resolve();
      });
    },
    setOpenTok: (apiKey = '', sessionId = '', token = '', logoUrl = null) => {
      return new Promise(async (resolve) => {
        await dispatch({
          type: "set",
          data: { otApiKey: apiKey, otSessionId: sessionId, otToken: token, logoUrl: logoUrl},
        });
        resolve();
      });
    },
  });

  return MemoContext;
};

export default MainMemo;
